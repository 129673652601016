import * as React from 'react'

type FollowContextType = {
  followedUsers: string[]
  followUser: (userId: string) => void
  unfollowUser: (userId: string) => void
}

const FollowContext = React.createContext<FollowContextType>(null!)

export const FollowProvider = ({ children }: { children: React.ReactNode }) => {
  const [followedUsers, setFollowedUsers] = React.useState<string[]>([])

  const followUser = (userId: string) => {
    setFollowedUsers(prev => {
      if (!prev.includes(userId)) {
        return [...prev, userId]
      }
      return prev
    })
  }

  const unfollowUser = (userId: string) => {
    setFollowedUsers(prev => prev.filter(id => id !== userId))
  }

  return (
    <FollowContext.Provider value={{ followedUsers, followUser, unfollowUser }}>
      {children}
    </FollowContext.Provider>
  )
}

export const useFollow = () => React.useContext(FollowContext)
