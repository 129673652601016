import { isError } from 'utils/js'

import { fromWsClientSubscription } from './from-ws-client-subscription'

export type Variables = Record<string, unknown> | undefined
type Callback<D> = (data: D | null) => unknown
type Query = string
type Key = string

const cache = new Map<Key, AsyncIterableIterator<unknown>>()
const cacheClearTimeouts = new Map<Key, NodeJS.Timeout>()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const callbacks = new Map<Key, Callback<any>[]>()

export function getOrMakeSubscription<D, V extends Variables>({
  query,
  callback,
  variables,
  token,
  subscriptionKey,
}: {
  query: Query
  callback?: Callback<D>
  variables?: V
  token: string
  subscriptionKey: string
}) {
  const key = getKey(query, variables)

  clearTimeout(cacheClearTimeouts.get(key))

  if (!cache.get(key)) {
    createSubscription(query, variables, token, subscriptionKey)
  }

  if (callback) {
    const current = callbacks.get(key)
    const next = current ? [...current, callback] : [callback]
    callbacks.set(key, next)
  }

  return () => {
    const current = callbacks.get(key) ?? []
    const next = callback && current ? current.filter(cb => cb !== callback) : []

    if (callback) {
      callbacks.set(key, next)
    }

    if (next.length === 0) {
      cleanUpSubscription(key)
    }
  }
}

function createSubscription(
  query: Query,
  variables: Variables,
  token: string,
  subscriptionKey: string,
) {
  const key = getKey(query, variables)

  const iterator = fromWsClientSubscription({
    query,
    variables,
    token,
    subscriptionKey,
  })

  ;(async () => {
    for await (const payload of iterator) {
      const current = callbacks.get(key)
      if (current) {
        for (const fn of current) {
          fn(payload.data)
        }
      }
    }
  })()

  cache.set(key, iterator)

  callbacks.set(key, [])
}

function cleanUpSubscription(key: Key) {
  clearTimeout(cacheClearTimeouts.get(key))

  const timeout = setTimeout(() => {
    try {
      const iterator = cache.get(key)
      iterator?.return?.()
      cache.delete(key)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        '[use-subscription] Did not unsubscribe iterator',
        isError(e) ? e.message : String(e),
      )
      cache.delete(key)
    }
  }, 1000)

  cacheClearTimeouts.set(key, timeout)
}

function getKey(query: Query, variables?: Variables) {
  return JSON.stringify({ query, variables })
}
