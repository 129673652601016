import * as React from 'react'
import { useTranslations } from 'next-intl'
import { useFollowUserMutation, useUnfollowUserMutation } from 'codegen/generated/flow'
import { tw } from 'utils/classnames'

import { useFollow } from '@ask-cf/contexts/follow-user-context'
import { StyledButton } from '../styled-button'

export function FollowButton({
  userId,
  hasSubtitle,
  following: initialFollowing,
}: {
  userId: string
  hasSubtitle: boolean
  following: boolean
}) {
  const { followedUsers, followUser, unfollowUser } = useFollow()

  const t = useTranslations('ask_cf.components.follow_button')
  const { mutate: followUserMutation, isLoading: isFollowUserLoading } = useFollowUserMutation()
  const { mutate: unfollowUserMutation, isLoading: isUnfollowUserLoading } =
    useUnfollowUserMutation()
  const [isHovered, setIsHovered] = React.useState(false)
  const isFollowing = followedUsers.includes(userId)

  React.useEffect(() => {
    if (initialFollowing && !isFollowing) {
      followUser(userId)
    }
  }, [initialFollowing, userId, followUser, isFollowing])

  function handleToggleFollow() {
    if (isFollowing) {
      unfollowUserMutation(
        { userID: userId },
        {
          onSuccess: data => {
            if (data.userUnfollow) {
              unfollowUser(userId)
            }
          },
        },
      )
    } else {
      followUserMutation(
        { userID: userId },
        {
          onSuccess: data => {
            if (data.userFollow) {
              followUser(userId)
            }
          },
        },
      )
    }
  }

  if (initialFollowing && isFollowing && !isHovered) {
    return null // Do not render the button if the user is already followed
  }

  return (
    <StyledButton
      noAnimation
      variant="transparent"
      size="minimal"
      className={tw(
        'text-xs font-medium leading-4 xl:pt-[1px]',
        hasSubtitle ? 'xl:self-start' : '',
      )}
      onClick={handleToggleFollow}
      disabled={isFollowUserLoading || isUnfollowUserLoading}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isFollowing
        ? isHovered
          ? t('unfollow_button_text')
          : t('followed_question_label')
        : t('follow_button_text')}{' '}
    </StyledButton>
  )
}
