import { createClient, type Client, type SubscribePayload } from 'graphql-ws'
import invariant from 'tiny-invariant'

let GRAPHQL_SUBSCRIPTIONS_URL: string | undefined

try {
  GRAPHQL_SUBSCRIPTIONS_URL =
    process.env.NEXT_PUBLIC_GRAPHQL_SUBSCRIPTIONS_URL ||
    import.meta.env.VITE_GRAPHQL_SUBSCRIPTIONS_URL
} catch {}

// we open different clients depending on auth state and user id.
// this means if you log out and open a subscription,
// we'll also start a new client.
// this helps in two ways:
// - ensures we don't leak auth data across clients
// - ensures we can have both unauthed subscriptions and authed subscriptions
//   open at the same time, that persist between auth sesssions. i.e. let's say
//   you start with an unauthed subscription, and then log in, we don't need
//   to close the subscription and reopen it. we can keep it there
const clients = new Map<string, Client>()

invariant(
  GRAPHQL_SUBSCRIPTIONS_URL,
  'GRAPHQL_SUBSCRIPTIONS_URL must be set inside packages/hooks/use-subscription',
)

function getClient(token: string, subscriptionKey: string) {
  if (!clients.get(subscriptionKey)) {
    const queryParams = token ? `?token=${token}` : ``

    const client = createClient({
      url: GRAPHQL_SUBSCRIPTIONS_URL + queryParams,
      connectionParams: function getConnectionParams() {
        return {
          token,
        }
      },
    })

    clients.set(subscriptionKey, client)
  }

  return clients.get(subscriptionKey)!
}

interface FromWsClientSubscriptionArgs extends SubscribePayload {
  token: string
  subscriptionKey: string
}

export function fromWsClientSubscription({
  token,
  subscriptionKey,
  ...payload
}: FromWsClientSubscriptionArgs) {
  return getClient(token, subscriptionKey).iterate(payload)
}
