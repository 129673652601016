import * as React from 'react'

import { useUserAuthQuery } from '../../generated/user'
import { getOrMakeSubscription, type Variables } from './lib/listeners'

// eslint-disable-next-line no-barrel-files/no-barrel-files
export { type Variables }

export type UseSubscriptionArgs<Data, V> = {
  subscription: string
  variables?: V
  callback?(data: Data | null): void
  enabled?: boolean
}

export function useSubscription<Data, V extends Variables = undefined>({
  subscription,
  variables,
  callback,
  enabled = true,
}: UseSubscriptionArgs<Data, V>) {
  const userAuthQuery = useUserAuthQuery()

  React.useEffect(() => {
    if (!enabled || !userAuthQuery.data?.me?.user?.id || !userAuthQuery.data?.me?.token) {
      return
    }

    return getOrMakeSubscription({
      query: subscription,
      variables,
      callback,
      subscriptionKey: userAuthQuery.data.me.user.id,
      token: userAuthQuery.data.me.token,
    })
  }, [
    subscription,
    userAuthQuery.data?.me?.user?.id,
    userAuthQuery.data?.me?.token,
    callback,
    variables,
    enabled,
  ])
}
